import axios from "axios";
import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertFiltersCustomToApi } from "@monorepo/catalog/src/views/VersionCatalogView/utils/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { IVersionElement } from "@monorepo/catalog/src/views/VersionCatalogView/types/versionElement";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  data: IVersionElement[];
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  infiniteId: string;
  cells: string[];
  isOpenFilters: boolean;
  section: Sections;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    //[fields.ARCHIVE_CODE]: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    //status: [elementStatuses[0].value],
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_CSV;
    case "XLSX":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_XLS;
    default:
      return QUERY_PATH.GET_CATALOG_ARCHIVE_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    sectionCells: {} as ISection,
    searchTemplates: [],
    totalLength: 0,
    cells: [],
    isOpenFilters: false,
    data: [],
    section: Sections.VERSION,
    isTableLoading: false,
    isLoadingToggleFilters: false,
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setData(state: ITableState, payload: IVersionElement[]) {
      state.data = payload;
    },
    addData(
      state: ITableState,
      payload: {
        data: IVersionElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    refreshInfiniteId(state: ITableState) {
      state.infiniteId = new Date().toString();
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getAutocompleteById(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-id/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { id: string }) => ({
          ...item,
          titleValue: item.id,
        }));
      }
      return [];
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async importCsvVersionFile({ dispatch }, { file }: { file: FormData }) {
      try {
        await axios.post(`${QUERY_PATH.IMPORT_VERSION_CSV}`, file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        dispatch("refreshScroll");
      } catch (e) {
        console.error(e);
        showNotification("Ошибка импорта файла: " + e.response?.data?.message ?? "");
      }
    },
    async getAutocompleteByVersion(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_VERSIONS_AUTOCOMPLETE,
        {
          version: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.version,
        }));
      }
      return [];
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IVersionElement[]>(QUERY_PATH.GET_CATALOG_VERSIONS_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), { root: true });
    },
    async addNewVersion(info, versionId: string): Promise<boolean> {
      try {
        const { data } = await axios.post(`${QUERY_PATH.GET_CATALOG_VERSIONS_LIST}/${versionId}`);
        return data?.data || {};
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text ||
            (e as unknown as error).response?.data?.message ||
            "Ошибка создания версии справочника."
        );
        return false;
      }
    },
    async sendVersionToNsi(info, params: { versionId: string; documentType: string }): Promise<void> {
      try {
        await axios.post(
          QUERY_PATH.SEND_VERSION_TO_NSI,
          {},
          {
            params,
          }
        );
        showNotification("Справочник успешно отправлен в ГАС НСИ", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text || (e as unknown as error).response?.data?.message || "Ошибка отправки в ГАС НСИ."
        );
      }
    },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
